@use "styles/colors";

.property-explorer {
  padding: 16px;
  background-color: colors.$white;
  border-left: 1px solid colors.$grey-3;
  display: flex;
  flex-flow: column;
  gap: 8px;
}
