.loading-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f1f5;
}
