@use "styles/colors";

.toolbar {
  color: colors.$black;
  background-color: colors.$pure-white;
  border-bottom: 1px solid colors.$grey-3;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 40px;

  h2 {
    margin: 0;
  }
}
