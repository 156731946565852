@use "styles/base.scss";

@import "~antd/dist/antd.css";

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}
