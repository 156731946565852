@use "styles/colors";

.stats {
  top: 0px !important;
  right: 0px !important;
  left: auto !important;
}

.canvas {
  width: 100vw;
  height: 100vh;
  background-color: colors.$grey-1;
  z-index: 1;
  position: relative;
}

.view {
  height: 100%;
  display: grid;
  grid-template-areas:
    "toolbar toolbar toolbar"
    "scene-explorer canvas-wrapper property-explorer"
    "timeline timeline property-explorer";
  position: relative;

  .toolbar {
    grid-area: toolbar;
  }

  .canvas-wrapper {
    position: relative;
    grid-area: canvas-wrapper;
  }

  .scene-explorer {
    grid-area: scene-explorer;
  }

  .property-explorer {
    grid-area: property-explorer;
  }

  .timeline {
    grid-area: timeline;
  }
}
