// Use this file to define any CSS variables.

:root {
  --color-primary: #5aa0e0;
  --color-primary-hover: #2d69b2;
  --color-primary-active: #154687;
  --color-primary-light: #e6f1fb;
  --color-primary-shadow: #5a9fe028;

  // Whites
  --color-white: #fafafa;
  --color-pure-white: #ffffff;

  // Blacks
  --color-black: #212121;
  --color-pure-black: #000000;
  --color-black-shadow: #00000010;

  // Greys
  --color-grey-1: #eeeeee;
  --color-grey-2: #e6e6e6;
  --color-grey-3: #bdbdbd;
  --color-grey-4: #9e9e9e;
  --color-grey-5: #757575;

  // Info colors
  --color-green-dark: #138956;
  --color-green: #25c189;
  --color-green-light: #cfefe3;
  --color-yellow-dark: #ffb10f;
  --color-yellow: #ffd766;
  --color-yellow-light: #fce8af;
  --color-red-dark: #9e2852;
  --color-red-hover: #c7264b;
  --color-red: #d64265;
  --color-red-light: #f3d5dc;
}
